import { NavHashLink as Link } from 'react-router-hash-link';

const CupCard = ({
    displayName,
    matchDates,
    enrollmentDates,
    location,
    categories,
}) => {
    const labelDate = 'Fechas';
    const labelEnrollment = 'Inscripciones';
    const labelLocation = 'Ubicación';
    const labelCategories = 'Categorías';

    return (
        <>
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-md text-secondary mb-1">{displayName}</p>
            <p className="text-xs sm:text-sm text-title truncate">
                {labelDate}: {matchDates}
            </p>
            <p className="text-xs sm:text-sm text-primary truncate">
                {labelEnrollment}: {enrollmentDates}
            </p>
            <p className="text-xs sm:text-sm text-primary truncate">
                {labelLocation}: {location}
            </p>
            <p className="text-xs sm:text-sm text-primary truncate">
                {labelCategories}: {categories}
            </p>
        </>
    );
};

const GridCups = ({ cups = [], description = '', title = '' }) => {
    const labelToDefine = 'Por definir';
    const labelComingSoon = 'Próximamente';
    const activeBorder =
        'relative rounded-lg px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-100 border-2 border-rose-300';
    const inactiveBorder =
        'relative rounded-lg px-4 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-gray-100 border-2 border-gray-200';

    return (
        <>
            <div className="relative pt-10">
                <h2 className="text-title-secondary text-center leading-8 tracking-tight sm:text-4xl sm:tracking-tight">
                    {title}
                </h2>
            </div>
            <div className="sm:px-12 sm:py-12 px-6 py-6">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {cups.map((cup) => (
                        <div
                            key={cup.uuid}
                            className={
                                cup.status === 1 ? activeBorder : inactiveBorder
                            }
                        >
                            <div className="flex-shrink-0">
                                <img
                                    className="h-16 w-16 rounded"
                                    src={cup.imageUrl}
                                    alt={cup.name}
                                />
                            </div>
                            <div className="flex-1 min-w-0 border-3">
                                {cup?.permalink === 'copa-rc-sur' ? (
                                    <a
                                        href={
                                            'https://docs.google.com/forms/d/e/1FAIpQLSeWDbp4Di4MmR7wro5vYyoXm5BcnBuEXjVewjz973m65ezoNA/viewform'
                                        }
                                        className="focus:outline-none"
                                    >
                                        <CupCard
                                            displayName={cup.displayName}
                                            matchDates={
                                                cup?.gallery?.dates ||
                                                labelComingSoon
                                            }
                                            enrollmentDates={
                                                cup?.gallery
                                                    ?.inscriptionDates ||
                                                labelComingSoon
                                            }
                                            location={
                                                cup.place || labelToDefine
                                            }
                                            categories={
                                                cup?.gallery?.categories ||
                                                labelToDefine
                                            }
                                        />
                                    </a>
                                ) : (
                                    <Link
                                        to={'/' + cup.permalink + '/#'}
                                        className="focus:outline-none"
                                    >
                                        <CupCard
                                            displayName={cup.displayName}
                                            matchDates={
                                                cup?.gallery?.dates ||
                                                labelComingSoon
                                            }
                                            enrollmentDates={
                                                cup?.gallery
                                                    ?.inscriptionDates ||
                                                labelComingSoon
                                            }
                                            location={
                                                cup.place || labelToDefine
                                            }
                                            categories={
                                                cup?.gallery?.categories ||
                                                labelToDefine
                                            }
                                        />
                                    </Link>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default GridCups;
